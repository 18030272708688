import axios from "axios";

import { item_endpoint, item_type_endpoint } from "./../../constant/api-endpoints";

let createProduct = function(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(item_endpoint, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let deleteProduct = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(item_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let editProduct = function(id, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(item_endpoint + "/" + id, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getProduct = function(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(item_endpoint + "/" + id, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getProductTypes = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(item_type_endpoint, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

let getProducts = function() {
    return new Promise((resolve, reject) => {
        axios
            .get(item_endpoint, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { createProduct, deleteProduct, editProduct, getProduct, getProductTypes, getProducts };
