import axios from "axios";

import { ticket_endpoint } from "./../../constant/api-endpoints";

let createTicket = function (params) {
  return new Promise((resolve, reject) => {
    axios
      .post(ticket_endpoint, params, {
        headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

let deleteTicket = function (id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(ticket_endpoint + "/" + id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

let editTicket = function (id, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(ticket_endpoint + "/" + id, params, {
        headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

let getTicket = function (id) {
  return new Promise((resolve, reject) => {
    axios
      .get(ticket_endpoint + "/" + id, {
        headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

let getTickets = function () {
  return new Promise((resolve, reject) => {
    axios
      .get(ticket_endpoint, {
        headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

let getTicketsForCustomer = function (id) {
  return new Promise((resolve, reject) => {
    axios
      .post(ticket_endpoint + "/customer/" + id)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

export default { createTicket, deleteTicket, editTicket, getTicket, getTickets, getTicketsForCustomer };
