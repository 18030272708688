import axios from "axios";

import { ticket_endpoint } from "./../../constant/api-endpoints";

let createLineItem = function(saleId, params) {
    let url = `${ticket_endpoint}/${saleId}/lineItem`;
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
            })
            .then(res => {
                return resolve(res.data);
            })
            .catch(err => {
                return reject(err.response);
            });
    });
};

// let deleteLineItem = function(id) {
//     return new Promise((resolve, reject) => {
//         axios
//             .delete(lineItem_endpoint + "/" + id)
//             .then((res) => {
//                 return resolve(res.data);
//             })
//             .catch((err) => {
//                 return reject(err.response);
//             });
//     });
// };

// let editLineItem = function(id, params) {
//     return new Promise((resolve, reject) => {
//         axios
//             .post(lineItem_endpoint + "/" + id, params)
//             .then((res) => {
//                 return resolve(res.data);
//             })
//             .catch((err) => {
//                 return reject(err.response);
//             });
//     });
// };

// let getLineItem = function(id) {
//     return new Promise((resolve, reject) => {
//         axios
//             .get(lineItem_endpoint + "/" + id)
//             .then((res) => {
//                 return resolve(res.data);
//             })
//             .catch((err) => {
//                 return reject(err.response);
//             });
//     });
// };

// let getLineItems = function() {
//     return new Promise((resolve, reject) => {
//         axios
//             .get(lineItem_endpoint)
//             .then((res) => {
//                 return resolve(res.data);
//             })
//             .catch((err) => {
//                 return reject(err.response);
//             });
//     });
// };

export default { createLineItem };
